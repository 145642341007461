import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next@15.0.3_@playwright+test@1.48.2_react-dom@19.0.0-rc-02c0e824-20241028_react@19.0.0-rc-02c_aab72nyowzcvumcxqyoxr23tba/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next@15.0.3_@playwright+test@1.48.2_react-dom@19.0.0-rc-02c0e824-20241028_react@19.0.0-rc-02c_aab72nyowzcvumcxqyoxr23tba/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next@15.0.3_@playwright+test@1.48.2_react-dom@19.0.0-rc-02c0e824-20241028_react@19.0.0-rc-02c_aab72nyowzcvumcxqyoxr23tba/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next@15.0.3_@playwright+test@1.48.2_react-dom@19.0.0-rc-02c0e824-20241028_react@19.0.0-rc-02c_aab72nyowzcvumcxqyoxr23tba/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next@15.0.3_@playwright+test@1.48.2_react-dom@19.0.0-rc-02c0e824-20241028_react@19.0.0-rc-02c_aab72nyowzcvumcxqyoxr23tba/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next@15.0.3_@playwright+test@1.48.2_react-dom@19.0.0-rc-02c0e824-20241028_react@19.0.0-rc-02c_aab72nyowzcvumcxqyoxr23tba/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next@15.0.3_@playwright+test@1.48.2_react-dom@19.0.0-rc-02c0e824-20241028_react@19.0.0-rc-02c_aab72nyowzcvumcxqyoxr23tba/node_modules/next/dist/lib/metadata/metadata-boundary.js");
